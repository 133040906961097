import React, { useState } from "react";
import styled from "styled-components";
import StyledText from "../../reusable/StyledText";
import { Column, Row } from "../../reusable/GenericStyledComponents";
import OptionsBlotter from "./OptionsBlotter";
import SpotBlotter from "./SpotBlotter";
import SpotOrders from "./SpotOrders";
import OptionOrders from "./OptionOrders";

const GeneralInfo: React.FC = () => {
  const [section, setSection] = useState(0);

  return (
    <PortfolioContainer>
      <NavigationContainer>
        <Item active={section === 0} onClick={() => setSection(0)}>
          Options Blotter
        </Item>
        <Item active={section === 1} onClick={() => setSection(1)}>
          Spot Blotter
        </Item>
        <Item active={section === 2} onClick={() => setSection(2)}>
          Options Orders
        </Item>
        {/* <Item active={section === 3} onClick={() => setSection(3)}>
          Spot Orders
        </Item> */}
      </NavigationContainer>

      <TableContainer>
        {section === 0 && <OptionsBlotter />}
        {section === 1 && <SpotBlotter />}
        {section === 2 && <OptionOrders />}
        {section === 3 && <SpotOrders />}
      </TableContainer>
    </PortfolioContainer>
  );
};

const PortfolioContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5%;
`;

const Item = styled.div<{ active: boolean }>`
  cursor: pointer;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${(p) => (p.active ? "#f0f0f0" : "#595959")};
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px 0 5px 0;
`;

const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95%;
  overflow: auto;
`;

export default GeneralInfo;
